<template>
<v-container fluid class="pa-0 pt-1 ma-0" style="margin-top:0 !important;">
<v-card style="z-index:1100;">
<v-layout  wrap>
    <v-flex xs12 class="pa-2">
        <v-list class="pb-0">
            <v-list-item 
                v-for="item in items" :key="item.tsk_id" 
                class="pb-0" @click="menuState(false)" :href="'/#' + $route.matched[1].path + '/sheet/' + item.tsk_id ">
                <v-list-item-avatar>
                    <v-icon large :color="(item.tsk_id == openedSheetId) ? 'green' : 'grey'">mdi-file-eye</v-icon>
                </v-list-item-avatar>
                <v-list-item-content style="border-bottom: 1px solid #CFD8DC">
                    <v-list-item-title :style="'color:' + item.sta_color + ';'">
                        #{{ item.tsk_tre_id }} <span class="caption grey--text">({{ item._info.tree.tre_loc_no + ' ' + item._info.tree.tre_address }})</span>
                    </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                    <v-chip small outlined label light :color="actionType['TSK'][item.tsk_typ_id].typ_color">
                        {{ actionType['TSK'][item.tsk_typ_id].typ_name }}
                    </v-chip>
                </v-list-item-action>
            </v-list-item>
        </v-list>
        <v-list class="pb-0" v-if="!itemsCount">
            <v-list-item>
                <v-list-item-content style="border-bottom: 1px solid #CFD8DC">
                    <v-list-item-title style="color:orange">
                        Aucune fiche en favoris
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-flex>
</v-layout>
</v-card>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheGetters, cacheData } from "../store/cache.module"

export default {
    name: 'view-open',
    props: ['filterDiff', 'menuState', 'tabsheetCount'],
    data: function () {
        return {
            saveProgress : false,
            locDateFormat: dateLocale, // Format de date
            actionType   : cacheGetters.getTypes(),
            menu         : false,
            //items: {}
        }
    },
    beforeMount: function (){

    },
    computed: {
        openedSheetId(){
            if( this.$route.params.tsk_id !== undefined && this.$route.params.tsk_id > 0 ){
                return this.$route.params.tsk_id
            } else {
                return false
            }
        },
        items() {
            return cacheData.bookmark
        },
        itemsCount(){
            var count = 0
            for(var prop in cacheData.bookmark) {
                if( prop.length > 0 ){
                    ++count
                }
            }
            return count
        }
    },
    methods: { },
    components: { }
}
</script>