<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-0" color="grey lighten-4" height="48px">
        <v-toolbar-title class="body-2 grey--text">
            <v-btn icon><v-icon >mdi-hammer-screwdriver</v-icon></v-btn>{{ objTask.tsk_id }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- <v-btn small rounded outlined depressed text color="primary" class="mr-2 white--text" @click="downloadPdf()">
            <v-icon dark>mdi-cloud-download-outline</v-icon><span class="hidden-sm-and-down"></span>
        </v-btn> -->
        <v-menu ref="tsk_date_due" v-model="dateMenu.tsk_date_due"
            :return-value.sync="objTask.tsk_date_due"
            :close-on-content-click="false" :nudge-right="40" transition="scale-transition" 
            offset-y min-width="290px" style="z-index:1500">
            <template v-slot:activator="{ on, attrs }">
            <v-chip small :color="(objTask.tsk_date_due) ? 'red' : 'grey'" text-color="white" v-bind="attrs" v-on="on" title="Date butoire">
                <v-btn icon><v-icon >mdi-calendar-clock</v-icon></v-btn>
                <span v-if="objTask.tsk_date_due">{{ locDateFormat.formatLocale( objTask.tsk_date_due, 'fr' ) }}</span>
            </v-chip>
            </template>
            <v-date-picker locale="fr-fr" no-title scrollable v-model="objTask.tsk_date_due">
                <v-btn color="red" @click="objTask.tsk_date_due = null"><v-icon>mdi-eraser</v-icon></v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="dateMenu.tsk_date_due = false">Annuler</v-btn>
                <v-btn color="primary" @click="$refs.tsk_date_due.save( objTask.tsk_date_due )">OK</v-btn>
            </v-date-picker>
        </v-menu>
        &nbsp;
        <v-chip small :color="getCacheType('TSK', objTask.tsk_typ_id).typ_color" :title="getCacheType('TSK', objTask.tsk_typ_id).typ_name" text-color="white">
            <v-btn icon><v-icon >mdi-tree-outline</v-icon></v-btn> {{ objTask.tsk_tre_id }}
        </v-chip>
        &nbsp;
        <v-chip small :color="getCacheType('TSZ', objTask.tsk_typ_id_zone).typ_color" :title="getCacheType('TSZ', objTask.tsk_typ_id_zone).typ_name" text-color="white">
            <v-btn icon><v-icon >mdi-compass-outline</v-icon></v-btn> {{ getCacheType('TSZ', objTask.tsk_typ_id_zone).typ_name }}
        </v-chip>
    </v-toolbar>
    <v-divider></v-divider>
    <v-container fluid class="mt-2 pa-2">

    <!-- Associative Tab --------------------------------------------------------------------------------------------------------->
    <v-card class="pb-3 elevation-3 rounded-lg" style="background-color:#FAFAFA;" light v-if="( objTask.tsk_id || taskCreate)">
        <v-tabs v-model="activeTab" grow light show-arrows="mobile">
            <v-tabs-slider color="green"></v-tabs-slider>
            
            <v-tab v-for="item in tabItems" :key="item.title" class="pl-1 pr-1 rounded-lg" style="border-bottom-left-radius: 20px !important; background-color:white;">
                <v-icon class="mr-1" :color="(activeTab.event == 0) ? 'red'  : 'grey'" :title="item.title">{{item.icon}}</v-icon>
                <span class="hidden-sm-and-down">
                    {{ item.title }}
                </span>
            </v-tab>
        </v-tabs>
        <v-card v-if="activeTab === 0 && showTab == true" class="elevation-0" fluid style="background-color:#FAFAFA;" light>
            <v-card-text>
            <v-layout row wrap class="ma-0 mt-2">
                <v-flex xs3 class="pa-2 pt-0">
                    <v-text-field :dense="fieldStyle.dense" label="N° OS" :value="objTask.tsk_os" disabled></v-text-field>
                </v-flex>
                <v-flex xs3 class="pa-2 pt-0">
                    <v-text-field :dense="fieldStyle.dense" label="ID Arbre" :value="objTask._info.tree.tre_ext_id" disabled></v-text-field>
                </v-flex>
                <v-flex xs6 class="pa-2 pt-0">
                    <v-text-field :dense="fieldStyle.dense" label="Arrondissement" :value="objTask._info.tree.tre_district" disabled></v-text-field>
                </v-flex>
                <v-flex xs1 class="pa-2 pt-0">
                    <v-text-field :dense="fieldStyle.dense" label="N°" :value="objTask._info.tree.tre_loc_no" disabled></v-text-field>
                </v-flex>
                <v-flex xs2 class="pa-2 pt-0">
                    <v-text-field :dense="fieldStyle.dense" label="Protection" :value="objTask.tsk_custom_fields.tsk_prot_type" disabled></v-text-field>
                </v-flex>
                <v-flex xs9 class="pa-2 pt-0">
                    <v-text-field :dense="fieldStyle.dense" label="Adresse" :value="objTask._info.tree.tre_address" disabled></v-text-field>
                </v-flex>

                <v-flex xs3 class="pl-2 pr-2">
                    <v-text-field :dense="fieldStyle.dense" :outlined="fieldStyle.outlined" prepend-icon="mdi-arrow-left-right"
                        label="Longueur" v-model="objTask.tsk_base_lenght" >
                    </v-text-field>
                </v-flex>
                <v-flex xs3 class="pl-2 pr-2">
                    <v-text-field :dense="fieldStyle.dense" :outlined="fieldStyle.outlined" prepend-icon="mdi-arrow-up-down"
                        label="Largeur" v-model="objTask.tsk_base_width" >
                    </v-text-field>
                </v-flex>
                <v-flex xs3 class="pl-2 pr-2">
                    <v-text-field :dense="fieldStyle.dense" :outlined="fieldStyle.outlined" prepend-icon="mdi-tree"
                        label="Profondeur" v-model="objTask.tsk_base_depth" >
                    </v-text-field>
                </v-flex>
                <v-flex xs3 class="pl-2 pr-2">
                    <v-text-field :dense="fieldStyle.dense" :outlined="fieldStyle.outlined" prepend-icon="mdi-diameter-variant"
                        label="Diamètre" v-model="objTask.tsk_base_diam" >
                    </v-text-field>
                </v-flex>
                <v-flex xs6 class="pl-2 pr-2">
                    <DatePicker label="Date plannification" :objectForm="objTask" fieldName='tsk_date_plan' :options="{ dense:true, outlined:true }" @change="checkTaskState()"></DatePicker>
                </v-flex>
                <v-flex xs6 class="pl-2 pr-2">
                    <v-select :dense="fieldStyle.dense" :outlined="fieldStyle.outlined"
                        item-text="ses_label" item-value="ses_id"
                        :items="getCacheSession()" v-model="objTask.tsk_ses_id" label="Session">
                    </v-select>
                </v-flex>
                <v-flex xs4 class="pl-2 pr-2">
                    <DatePicker label="Essouchage" :objectForm="objTask" fieldName='tsk_date_ess' :options="{ dense:true, outlined:true }" @change="checkTaskState()"></DatePicker>
                </v-flex>
                <v-flex xs4 class="pl-2 pr-2">
                    <DatePicker label="Fouille" :objectForm="objTask" fieldName='tsk_date_fou' :options="{ dense:true, outlined:true }" @change="checkTaskState()"></DatePicker>
                </v-flex>
                <v-flex xs4 class="pl-2 pr-2">
                    <DatePicker label="Pavage" :objectForm="objTask" fieldName='tsk_date_pro' :options="{ dense:true, outlined:true }" @change="checkTaskState()"></DatePicker>
                </v-flex>
                <v-flex xs12 class="pl-2 pr-2">
                    <v-textarea :dense="fieldStyle.dense" :outlined="fieldStyle.outlined" prepend-icon="mdi-comment"
                        rows="2" label="Commentaire"  v-model="objTask.tsk_custom_fields.tsk_comment" >
                    </v-textarea>
                </v-flex>
                <v-flex xs6 class="pl-2 pr-2">
                    <v-select 
                        :dense="fieldStyle.dense" :outlined="fieldStyle.outlined" item-text="sta_label" item-value="sta_id"
                        :items="getCacheState('GEN')" v-model="objTask.tsk_sta_id" label="Statut" single-line>
                        <template v-slot:prepend><v-icon :color="getCacheState('GEN', objTask.tsk_sta_id).sta_color">mdi-file-tree</v-icon></template>
                    </v-select>
                </v-flex>
                <v-flex xs6 class="pl-2 pr-2">
                <v-select 
                    :dense="fieldStyle.dense" :outlined="fieldStyle.outlined" item-text="typ_name" item-value="typ_id"
                    :items="getCacheType('TSK')" v-model="objTask.tsk_typ_id" label="Type" single-line>
                    <template v-slot:prepend><v-icon :color="getCacheType('TSK', objTask.tsk_typ_id).typ_color">mdi-progress-check</v-icon></template>
                </v-select>
                </v-flex>
            </v-layout>
            </v-card-text>
        </v-card>

        <DocumentList   v-if="activeTab === 1 && showTab == true" :routeState="routeState" :doc_xxx_id="objTask.tsk_id" :table_id="'tsk'" @linkDoc="linkedDocument"></DocumentList>
        <EventList v-if="activeTab === 2 && showTab == true" :routeState="routeState" :eve_xxx_id="objTask.tsk_id" :table_id="'tsk'" typfilter="ALT" title="Alerte"></EventList>
    </v-card>
    <!-- Save button form -->
    <v-toolbar v-if="activeTab === 0 && showTab == true" extended flat color="transparent" >
        <v-btn v-if="$isAuthorized( 'TaskPolicy', 'store' )" rounded color="green" class="mr-3" dark :loading="saveProgress" @click.native="objTask.tsk_sta_id = 'GEN03'; saveForm();" :disabled="saveProgress">
            <v-icon left>mdi-record</v-icon>Terminer
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn rounded text color="#595959" dark :href="link.base">
            <v-icon left dark>mdi-close</v-icon>Annuler
        </v-btn>
        <v-btn v-if="$isAuthorized( 'TaskPolicy', 'store' )" rounded color="primary" class="mr-3 ml-3" :loading="saveProgress" @click.native="saveForm()" :disabled="(saveProgress || !objTask.tsk_ses_id) ? true : false">
            <v-icon left>mdi-content-save-outline</v-icon>Enregistrer
        </v-btn>
    </v-toolbar>
    </v-container>
</v-container>
</template>

<script>
import dateLocale  from '@/services/dateLocale';
import EventList    from '@/components/Tab_event.vue'
import DocumentList from '@/components/Tab_document.vue'
import DatePicker  from '@/components/ui/DatePicker'

import { cacheGetters } from "../store/cache.module"
import { tskObj }       from '../mixins/tsk_obj.js'

import { taskUpdateService } from '@/rxjsServices';

export default {
    name: 'object_edit',
    props: [ 'switchModal', 'routeState', 'link' ],
    mixins: [ tskObj ],
    components: { EventList, DocumentList, DatePicker },
    data: function () {
        return {
            saveProgress: false,         // Enregistrement en cours...
            fieldStyle: { outlined: true, dense: true },
            formData    : false,
            dateMenu: { tsk_date_plan: false, tsk_date_end: false, tsk_date_due: false, tsk_date_ess: false, tsk_date_fou: false, tsk_date_pro: false, tsk_date_exec: false },
            locDateFormat: dateLocale, // Format de date
            obtObj: { _method:'POST', _path:'objecttype/' },
            obtObjClone:'',
            formItem: [],
            activeTab: -1, 
            showTab: false,
            tabItems: [ { title: 'Fiche', icon: 'mdi-traffic-cone'}, { title: 'Fichiers', icon: 'mdi-folder-multiple-outline'}, { title: 'Alertes', icon: 'mdi-alert'} ],
            primaryItem: 30,
            secondaryItem: 4,
            treePic: "",
            listsData: {},
            layerName: '',
            taskCreate: false
      }
    },
    mounted: function (){
        // Chargement des info
        setTimeout( () => {
            this.taskCreate = false
            if( this.$route.params.tsk_id !== undefined && this.$route.params.tsk_id > 0 ){
                this.objTask.tsk_id = this.$route.params.tsk_id
                if( this.objTask.tsk_id > 0 ){
                    this.getForm()
                }
                this.activeTab = 0
            }
            if( this.$route.params.tre_id !== undefined && this.$route.params.tre_id > 0 ){
                this.objTask.tsk_tre_id = this.$route.params.tre_id
                this.getTree()
                this.objTask.tsk_id = 0
                this.taskCreate = true
                this.showTab    = true
            }
            //this.getImage()
        }, 400) 
    },
    methods: {
        async getForm(){
            await this.getTaskShow('tree')
            this.formData     = true
            this.showTab      = true
        },
        async saveForm(){
            this.saveProgress = true
            if( !this.objTask.tsk_id ){
                await this.postTaskStore()
                this.$root.$toast({ text: 'Fiche enregistrée !' })
            } else {
                await this.putTaskStore()
                this.$root.$toast({ text: 'Fiche enregistrée !' })
            }
            this.saveProgress = false
            taskUpdateService.sendTask(this.objTask);
        },
        getTree(){
            this.$http.get( '/trees/' + this.objTask.tsk_tre_id ).then( (response) => {
                this.objTask._info.tree = response.data.data
            })
        },
        cleanForm(){
            var obtClone = JSON.parse( this.obtObjClone )
            var prop
            for( prop in obtClone ){
                if( this.obtObj[prop] !== undefined ){
                    this.obtObj[prop] = obtClone[prop]
                }
            }
        },
        linkedDocument(doc_id){
            this.getImage(doc_id)
        },
        getImage(doc_id){
            this.$http.customRequest({ method: 'get', url: 'documents/' + doc_id + '/download', responseType: 'blob' } )
            .then( (response) => {
                if( response.status == 204 ){
                    this.$root.$toast({ color: 'warning', text: 'Le fichier image n\'existe pas' })
                } else {
                    var reader = new FileReader();
                    reader.readAsDataURL(response.data); 
                    reader.onloadend = () => {
                        var base64data = reader.result;                
                        this.treePic = base64data;
                    }
                }
            }).catch( (error) => {
                this.$root.$toast({ color: 'error', text: error })
            })
        },
        getCacheType       : cacheGetters.getTypes,
        getCacheState      : cacheGetters.getStates,
        getCacheSession    : cacheGetters.getSession,
    },
}
</script>