<template>
    <v-snackbar style="z-index:4000;"
      :timeout="2000" :color="toastOption.color"
      :top="toastOption.top" :bottom="toastOption.bottom" 
      :left="toastOption.left" :right="toastOption.right" v-model="dialog" >
        {{toastOption.text}}
    </v-snackbar>
</template>

<script>
export default {
    data: () => ({
        dialog: false,
        resolve: null,
        reject: null,
        toastDefault: { color: '', top:false, bottom:true, right:true, left:false, text:'Fiche enregistré' },
        toastOption:  { color: '', top:false, bottom:true, right:true, left:false, text:'Fiche enregistré' },
    }),
    methods: {
        open(options) {
            this.dialog = true
            this.toastOption = Object.assign(this.toastOption, options)
            return new Promise((resolve, reject) => {
                this.resolve = resolve
                this.reject  = reject
            })
        },
        cancel() {
            this.resolve(false)
            this.dialog = false
        }
    }
}
</script>