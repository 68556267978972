var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-toolbar',{staticClass:"elevation-0",attrs:{"flat":"","color":"barrinfo"}},[_c('v-toolbar-title',{class:_vm.contextStyle.titleClass},[_c('v-icon',{class:_vm.contextStyle.titleIcon,attrs:{"large":"","color":_vm.contextStyle.titleColor}},[_vm._v(_vm._s(_vm.contextStyle.icon))]),_c('span',[_vm._v(_vm._s(_vm.contextStyle.title))])],1),_c('v-spacer')],1),_c('v-card',{staticClass:"ma-4 rounded-lg"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"sta_id","search":_vm.search,"items-per-page-text":"Lignes par page","items-per-page-options":_vm.pagination.rowsPerItem},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"rounded-lg ma-0 pa-0",staticStyle:{"background-color":"#FAFAFA"}},[_c('v-col',{staticClass:"pa-1",attrs:{"cols":"6","md":"6"}},[_c('v-text-field',{staticClass:"pa-2 rounded-xl",attrs:{"append-icon":"mdi-magnify","label":"Recherche","outlined":"","dense":"","clearable":"","single-line":"","hide-details":""},on:{"blur":function($event){return _vm.saveSearchToCache()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"text-right pa-1",attrs:{"cols":"6","md":"6"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-2",attrs:{"color":"success","outlined":"","loading":_vm.loading.getXls},on:{"click":function($event){return _vm.downloadTask()}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-file-excel")])],1)]}}])},[_c('span',[_vm._v("Telecharger xlsx")])])],1)],1)]},proxy:true},{key:"item.tre_address",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-inline-block text-truncate caption",staticStyle:{"max-width":"150px"},attrs:{"title":item.tre_address}},[_vm._v(" "+_vm._s(item.tre_address)+" ")])]}},{key:"item.tsk_sta_id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","outlined":"","label":"","color":_vm.getCacheState('GEN', item.tsk_sta_id).sta_color}},[_vm._v(" "+_vm._s(_vm.getCacheState('GEN', item.tsk_sta_id).sta_label)+" ")])]}},{key:"item.tsk_typ_id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","outlined":"","label":"","color":_vm.getCacheType('TSK', item.tsk_typ_id).typ_color}},[_vm._v(" "+_vm._s(_vm.getCacheType('TSK', item.tsk_typ_id).typ_name)+" ")])]}},{key:"item.tsk_date_plan",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","outlined":"","label":""}},[_vm._v(" "+_vm._s((item.tsk_date_plan) ? _vm.$date(item.tsk_date_plan).format('DD/MM/YYYY') : 'NON')+" ")])]}},{key:"item.tsk_id",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ml-2 elevation-1",attrs:{"outlined":"","small":"","color":"grey","href":'/#/tasks/sheet/' + item.tsk_id + ''}},[_c('v-icon',{staticClass:"mr-2",attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v("Ouvrir ")],1)]}},{key:"pageText",fn:function(ref){
var pageStart = ref.pageStart;
var pageStop = ref.pageStop;
return [_vm._v(" De "+_vm._s(pageStart)+" à "+_vm._s(pageStop)+" ")]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }