<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-0" flat color="barrinfo">
        <v-toolbar-title :class="contextStyle.titleClass">
            <v-icon large :color="contextStyle.titleColor" :class="contextStyle.titleIcon">{{ contextStyle.icon }}</v-icon>
            <span>{{ contextStyle.title }}</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
    </v-toolbar>

    <v-card class="ma-4 rounded-lg">
    <v-data-table v-bind:headers="headers" :items="items" item-key="sta_id" :search="search"
        items-per-page-text="Lignes par page" :items-per-page-options="pagination.rowsPerItem">
        <template v-slot:top>
            <v-row class="rounded-lg ma-0 pa-0" style="background-color: #FAFAFA">
                <v-col cols="6" md="6" class="pa-1">
                <v-text-field append-icon="mdi-magnify" label="Recherche" outlined dense clearable single-line
                            hide-details v-model="search" @blur="saveSearchToCache()" class="pa-2 rounded-xl">
                </v-text-field>
                </v-col>
                <v-col cols="6" md="6" class="text-right pa-1">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" color="success" outlined :loading="loading.getXls" class="ma-2" @click="downloadTask()">
                                <v-icon dark>mdi-file-excel</v-icon>
                            </v-btn>
                        </template>
                        <span>Telecharger xlsx</span>
                    </v-tooltip>
                </v-col>
            </v-row>
        </template>
        <template v-slot:[`item.tre_address`]="{ item }">
            <span class="d-inline-block text-truncate caption" style="max-width: 150px;" :title="item.tre_address">
                {{item.tre_address}}
            </span>
        </template>
        <template v-slot:[`item.tsk_sta_id`]="{ item }">
            <v-chip small outlined label :color="getCacheState('GEN', item.tsk_sta_id).sta_color">
                {{ getCacheState('GEN', item.tsk_sta_id).sta_label }}
            </v-chip>
        </template>
        <template v-slot:[`item.tsk_typ_id`]="{ item }">
            <v-chip small outlined label :color="getCacheType('TSK', item.tsk_typ_id).typ_color">
                {{ getCacheType('TSK', item.tsk_typ_id).typ_name }}
            </v-chip>
        </template>
        <template v-slot:[`item.tsk_date_plan`]="{ item }">
            <v-chip small outlined label>
                {{(item.tsk_date_plan) ? $date(item.tsk_date_plan).format('DD/MM/YYYY') : 'NON'}}
            </v-chip>
        </template>
        <template v-slot:[`item.tsk_id`]="{ item }">
            <v-btn outlined small color="grey" class="ml-2 elevation-1" :href="'/#/tasks/sheet/' + item.tsk_id + ''">
                <v-icon left class="mr-2">mdi-pencil</v-icon>Ouvrir
            </v-btn>
        </template>
        <template slot="pageText" slot-scope="{ pageStart, pageStop }">
        De {{ pageStart }} à {{ pageStop }}
        </template>
    </v-data-table>
    </v-card>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheGetters, cacheMutations } from "../store/cache.module"
import { tskObj }       from '../mixins/tsk_obj.js'

import { filterUpdateService } from '@/rxjsServices';

export default {
    name: 'tasks',
    props: ['contextStyle'],
    mixins: [ tskObj ],
    data: function () {
        return {
            loading: { getXls: false, getTasks: false },         // Enregistrement en cours...
            search: '',
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            headers: [
                { text: 'OS',           align: 'left', value: 'tsk_os'},
                { text: 'N°',           align: 'left', value: 'tre_loc_no'},
                { text: 'Adresse',      align: 'left', value: 'tre_address', width: '150px'},
                { text: 'L.',           align: 'left', value: 'tsk_base_lenght'},
                { text: 'l.',           align: 'left', value: 'tsk_base_width'},
                { text: 'P.',           align: 'left', value: 'tsk_base_depth'},
                { text: 'Souche',       align: 'left', value: 'tsk_base_diam'},
                { text: 'Etat',         align: 'left', value: 'tsk_sta_id'},
                { text: 'Avancement',   align: 'right',value: 'tsk_typ_id' },
                { text: 'Planif.',     align: 'right',value: 'tsk_date_plan' },
                { text: 'Modification', align: 'right',value: 'tsk_id' },
            ],
            items: [],
            locDateFormat: dateLocale,
            enableAdd: true,
            modalEvent : false,
            cacheTab: this.$storage.localStorage.get( this.$APP_COD + "_cache" ),
        }
    },
    computed: {
        pages () {
            if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null ) return 0
            return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
        }
    },
    created(){
        this.subTask = filterUpdateService.getFilter().subscribe(message => {
            if (message) {
                this.getItems()
            }
        })
    },
    mounted: function (){
        this.search = cacheGetters.getSearchBarItem('tasks') || ''
        this.getItems()
    },
    methods: {
        async getItems(){
            this.items = []
            let url    = '?per_page=false&extended=withtree,withactivealert'
            let option = cacheGetters.getFilterUrl()
            url    += ( option.length ) ? '&' + option : ''
            this.getTaskIndex(false, false, url).then( () => {
                this.items = this.listTask //response.data.data
            })
        },
        handlePage(index){
            this.pagination.limit = this.pagination.rowsPerPage * (index - 1)
        },
        getTypeName( typ_id ){
            if( this.cacheTab.types['SPE'] !== undefined && this.cacheTab.types['SPE'][typ_id] !== undefined ){
                return this.cacheTab.types['SPE'][typ_id].typ_name 
            } else {
                return 'non définit'
            }
        },
        saveSearchToCache(){
            cacheMutations.setSearchBarItem('tasks', this.search)
        },
        downloadTask(){
            this.loading.getXls = true
            let searchCriteria = this.getTaskFilter()
            this.$http.customRequest({ method: 'get', url: '/report/tasks/' + searchCriteria, responseType: 'blob' } )
            .then( (response) => {
                this.loading.getXls = false
                this.$download(response.data, "fosses.xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
            }).catch( () => { 
                this.loading.getXls = false
            })
        },
        getCacheType: cacheGetters.getTypes,
        getCacheState: cacheGetters.getStates
    },
    beforeDestroy() {
        this.subTask.unsubscribe()
    },
    components: {
        
    }
}
</script>