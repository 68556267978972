<template>
<v-container fluid class="pa-0 ma-0 v-dialog--scrollable" style="max-width: 540px; max-height: 80%;">
<v-card style="z-index:1000;" light class="elevation-0">
<v-app-bar flat color="primary" dense dark>
    <v-toolbar-title><v-icon class="mr-3" color="success">mdi-cogs</v-icon> Filtre général</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn text class="pl-0" @click="clearFilter"><v-icon :color="(filterCount) ? 'red' : 'grey lighten-5'" title="Réinitialiser mon filtre">mdi-refresh</v-icon></v-btn>
    <v-btn text :class="(filterCount) ? 'red--text' : ''" @click="saveFilter" title="Enregistrer le filtre">
        <v-icon :color="(filterCount) ? 'green' : 'grey lighten-5'">mdi-content-save</v-icon>
    </v-btn>
</v-app-bar>

<v-layout row wrap class="ma-0 pl-2 pr-2 pt-3 pb-4">
    <v-flex xs6 class="pl-2 pr-2">
        <DatePicker label="Date début" :objectForm="usrFilter" fieldName='min_tsk_date_plan'></DatePicker>
    </v-flex>
    <v-flex xs6 class="pl-2 pr-2">
        <DatePicker label="Date début" :objectForm="usrFilter" fieldName='max_tsk_date_plan'></DatePicker>
    </v-flex>
    <v-flex xs12 class="pl-2 pr-2 pa-0">
        <v-select :dense="fieldStyle.dense" :outlined="fieldStyle.outlined"
            item-text="ses_label" item-value="ses_id"
            :items="filterSource.ses" v-model="ses_id" label="Session" >
            <template v-slot:prepend><v-icon>mdi-calendar-month</v-icon></template>
        </v-select>
    </v-flex>
    <v-flex xs6 class="pa-0 pr-2">
        <v-subheader inset>Statut</v-subheader>
        <v-alert border="left" class="ma-0 mb-1 pa-0" dense :colored-border="( usrFilter.tsk_sta_id.includes(item.sta_id) ? false : true )"
            elevation="1" v-for="(item, index) in filterSource.tsk_sta" :key="index" :color="item.sta_color">
            <v-row align="center" >
                <v-col class="pa-1 pl-6 grow">{{ item.sta_label }}</v-col>
                <v-col class="pa-1 pr-4 shrink"><v-switch v-model="usrFilter.tsk_sta_id" :value="item.sta_id" color="white"></v-switch></v-col> <!-- :color="item.sta_color" -->
            </v-row>        
        </v-alert>
    </v-flex>
    <v-flex xs6 class="pa-0 pr-2">
        <v-subheader inset>Type</v-subheader>
        <v-alert border="left" class="ma-0 mb-1 pa-0" dense :colored-border="( usrFilter.tsk_typ_id.includes(item.typ_id) ? false : true )"
            :color="item.typ_color" elevation="1" v-for="(item, index) in filterSource.tsk_typ" :key="index">
            <v-row align="center" >
                <v-col class="pa-1 pl-6 grow">{{ item.typ_name }}</v-col>
                <v-col class="pa-1 pr-4 shrink"><v-switch v-model="usrFilter.tsk_typ_id" :value="item.typ_id" color="white"></v-switch></v-col> <!-- :color="item.sta_color" -->
            </v-row>        
        </v-alert>
    </v-flex>
</v-layout>
<v-card-actions>
    <v-spacer></v-spacer>
    <v-btn small rounded color="success" @click="closeWindow()">Appliquer</v-btn>
</v-card-actions>
</v-card>
</v-container>
</template>

<script>
import dateLocale  from '../services/dateLocale';
import { cacheData, cacheGetters, cacheMutations, cacheActions } from "../store/cache.module"
//import autoCompMulti    from './Autocomplete_multi.vue'
//import { store, mutations } from "../services/store";
import { filterUpdateService } from '@/rxjsServices';
import DatePicker from '@/components/ui/DatePicker'

export default {
    name: 'view-filter',
    props: ['filterDiff', 'filterCount', 'filterView'],
    components: { DatePicker },
    data: function () {
        return {
            fieldStyle: { outlined: true, dense: true },
            locDateFormat: dateLocale, // Format de date
            saveProgress: false,
            loading: { getItem: true },
            typSit: [],
            menu: false,
            dateMenu: { tsk_date_plan: false, tsk_date_end: false },
            ses_id    : undefined,
            usrFilter: {
                tsk_sta_id: [],
                tsk_typ_id: [],
                min_tsk_date_plan: null, 
                max_tsk_date_plan: null
            },
            filterSource:{
                tsk_sta: [],
                tsk_typ_id: this.getCacheType('TSK'),
                ses: [],
                typ: [],
            },
            typSource: [],

            filterTab : [],
            sitTab : [],
            storeFilter: {},
            eveState: this.getCacheState('GEN'),
            user : cacheData.user
        }
    },
    watch: {
        filterView () {
            cacheMutations.setFilter(this.usrFilter)
            this.updateFilter()
        }
    },
    beforeMount: function(){
        if( this.user.usr_ses_id ){
            this.ses_id = this.user.usr_ses_id
        }
        this.initFilter()
    },
    mounted:function (){
        
    },
    methods: {
        initFilter(){
            let cacheFilter     = cacheGetters.getFilter()
            for(var prop in this.usrFilter){
                if( cacheFilter[prop] ){
                    this.usrFilter[prop] = cacheFilter[prop]
                }
            }
            //this.usrFilter      = cacheGetters.getFilter()
            this.storeFilter    = JSON.parse( JSON.stringify( this.usrFilter ) ) // todoo init if empty
            this.filterSource.tsk_typ = cacheGetters.getTypes( 'TSK' )
            this.filterSource.tsk_sta = this.getCacheState('GEN')
            this.filterSource.tsk_sta = this.getCacheState('GEN').filter(sta => sta.sta_visibility === 1)
            this.filterSource.ses     = this.getCacheSession()
        },
        updateFilter(){
            var total = 0
            var diff  = []
            for( let prop in this.usrFilter ){
                if( this.usrFilter[prop] && Array.isArray( this.usrFilter[prop] ) && this.storeFilter[prop] ){
                    diff = this.storeFilter[prop].filter(x => this.usrFilter[prop].indexOf(x) < 0 )
                    total += diff.length
                    diff = this.usrFilter[prop].filter(x => this.storeFilter[prop].indexOf(x) < 0 )
                    total += diff.length
                }
            }
            this.$emit('update:filterCount', total)
        },
        removeItem (item) {
            let index = this.usrFilter.tsk_typ_id.indexOf(item.sit_id)
            if (index >= 0) { 
                this.usrFilter.tsk_typ_id.splice(index, 1)
            }
        },
        saveFilter(){
            cacheMutations.setFilter( this.usrFilter )
            cacheActions.storeCacheToStorage()
            this.storeFilter = JSON.parse( JSON.stringify( this.usrFilter ) )
        },
        clearFilter(){
            var usrFilter      = cacheGetters.getFiltrerFromStorage()
            for(var prop in usrFilter){
                if( Object.prototype.toString.call( this.usrFilter[prop] ) === '[object Array]' ) {
                    this.usrFilter[prop] = usrFilter[prop].slice( this.usrFilter[prop] )
                } else if( this.usrFilter[prop] !== undefined ){
                    this.usrFilter[prop] = usrFilter[prop]
                }
            }
            this.updateFilter()
        },
        resetFilter(){
            for( let prop in this.usrFilter ){
                if( this.usrFilter[prop] && Array.isArray( this.usrFilter[prop] ) && this.storeFilter[prop] ){
                    this.usrFilter[prop] = []
                } else {
                    this.usrFilter[prop] = null
                }
            }
        },
        closeWindow(){
            cacheMutations.setFilter(this.usrFilter)
            this.$emit('update:filterView', false)
            filterUpdateService.sendFilter('filter')
        },
        removeChip (fromFilter, id) {
            const index = this.usrFilter[fromFilter].indexOf(id)
            if (index >= 0) this.usrFilter[fromFilter].splice(index, 1)
        },
        getCacheState   : cacheGetters.getStates,
        getCacheType    : cacheGetters.getTypes,
        getCacheSession : cacheGetters.getSession,
    },
}
</script>